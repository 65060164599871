<template>
	<div class="bg-F7F7F7 paddt20">
		<div class="bg-white w1200 margin-center">
			<div class="padd20 display-flex">
				<div>
					<img class="de-01 display-block" src="@/assets/img/img_02.png" />
				</div>
				<div class="fz28 co-333333 marl20">家装CAD实例讲解-原始框架图绘制</div>
			</div>
		</div>
		
		<div class="mart10">
			<div class="w1200 margin-center bg-white">
				<div class="padd21">
					<div class="catalogue">
						<div class="">
							<div class="paddlr20 h44 align-items-center fz16 co-333333 bg-F7F7F7">第一章：学前须知</div>
							<div class="paddlr20 h44 justify-content-center-between fz16">
								<div class="align-items-center">
									<img class="cd_01 display-block" src="@/assets/img/cd_01.png" alt="" />
									<span class="fz16 co-999999 display-block marl14">第1节-课程介绍</span>
								</div>
								<div class="btn-01 center co-010085 fz12 cure-pointer">确认学习</div>
							</div>
							<div class="paddlr20 h44 justify-content-center-between fz16 bg-F9FFD6">
								<div class="align-items-center">
									<img class="cd_01 display-block" src="@/assets/img/cd_02.png" alt="" />
									<span class="fz16 co-010085 display-block marl14">第2节-如何使用联系文件</span>
								</div>
								<div class="btn-01 center co-010085 fz12 cure-pointer">确认学习</div>
							</div>
							<div class="paddlr20 h44 justify-content-center-between fz16">
								<div class="align-items-center">
									<img class="cd_01 display-block" src="@/assets/img/cd_01.png" alt="" />
									<span class="fz16 co-999999 display-block marl14">第3节-新功能持续更新</span>
								</div>
								<div class="btn-01 center co-010085 fz12 cure-pointer">确认学习</div>
							</div>
						</div>
						<div class="">
							<div class="paddlr20 h44 align-items-center fz16 co-333333 bg-F7F7F7">第二章：编辑图片</div>
							<div class="paddlr20 h44 justify-content-center-between fz16">
								<div class="align-items-center">
									<img class="cd_01 display-block" src="@/assets/img/cd_01.png" alt="" />
									<span class="fz16 co-999999 display-block marl14">第1节-课程介绍</span>
								</div>
								<div class="co-010085 fz12">已学习</div>
							</div>
							<div class="paddlr20 h44 justify-content-center-between fz16">
								<div class="align-items-center">
									<img class="cd_01 display-block" src="@/assets/img/cd_01.png" alt="" />
									<span class="fz16 co-999999 display-block marl14">第2节-如何使用联系文件</span>
								</div>
								<div class="co-010085 fz12">已学习</div>
							</div>
							<div class="paddlr20 h44 justify-content-center-between fz16">
								<div class="align-items-center">
									<img class="cd_01 display-block" src="@/assets/img/cd_01.png" alt="" />
									<span class="fz16 co-999999 display-block marl14">第3节-新功能持续更新</span>
								</div>
								<div class="co-010085 fz12">已学习</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'FamousTeacherDetail',
		data() {
			return {
				state: 1, // 列表加载状态
				page: 0, // 分页
				loading: false, // 接口加载状态
				list: [], // 列表
			}
		},
		methods: {
		}
	}
</script>

<style lang="less" scoped>
	.de-01 {
		width: 285px;
		height: 220px;
	}
	
	.catalogue {
		width: 1160px;
		border: 1px solid #E6E6E6;
		
		.cd_01 {
			width: 22px;
			height: 22px;
		}
		
		.btn-01 {
			width: 60px;
			height: 24px;
			background: #DCFF03;
			border-radius: 2px;
		}
		
		.btn-01:hover {
			background: #010085;
			color: #DCFF03;
		}
	}
	
</style>
